/* DarkTheme.css */

body, .App, .chart-container {
    background-color: #1E1E26 !important; /* Dark background */
  }
  
  .chart-container {
    padding: 20px;
  }
  
  /* Adjust the title color if needed */
  .title {
    color: #BEC2CC;
  }
  
  /* Additional styles for other elements */
  /* ... */
  